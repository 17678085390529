var render = function render(){
  var _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "promo__section"
    }
  }, [_c('documents-filters', {
    on: {
      "createHead": _vm.createHead,
      "change_status": _vm.change_status,
      "change_type": _vm.change_type
    }
  }), _c('resizable-table', {
    attrs: {
      "busy": _vm.isBusy,
      "table_name": "promo_heads",
      "items": _vm.items,
      "default_fields": _vm.fields,
      "empty_text": "Ничего не найдено"
    },
    on: {
      "row-click": _vm.rowClick
    },
    scopedSlots: _vm._u([{
      key: "body_id",
      fn: function (data) {
        return [_c('div', {
          staticClass: "b-statuses",
          class: {
            success: (data === null || data === void 0 ? void 0 : data.item.status) === 'ACTIVE',
            error: (data === null || data === void 0 ? void 0 : data.item.status) === 'ERRORED',
            awaiting: (data === null || data === void 0 ? void 0 : data.item.status) === 'AWAITING',
            finished: (data === null || data === void 0 ? void 0 : data.item.status) === 'FINISHED'
          }
        }), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "position-relative",
          staticStyle: {
            "width": "18px",
            "height": "18px",
            "margin-right": "10px"
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('e-checkbox', {
          attrs: {
            "position": 'center',
            "checked": _vm.isSelectedItem(data.item.id)
          },
          on: {
            "click": function ($event) {
              return _vm.selectRow(data.item.id);
            }
          }
        })], 1), _c('div', {
          staticClass: "d-flex flex-column align-items-start"
        }, [_c('div', [_vm._v(_vm._s(data.item.name || '-'))]), _c('span', {
          staticClass: "head-info"
        }, [_vm._v(" " + _vm._s(data.item.number) + "    " + _vm._s(_vm.formatDate(data.item.date, 'date')) + " ")])])])];
      }
    }, {
      key: "body_start_date",
      fn: function (data) {
        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s(_vm.formatDate(data.item.start_date)) + " ")])];
      }
    }, {
      key: "body_end_date",
      fn: function (data) {
        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s(_vm.formatDate(data.item.end_date)) + " ")])];
      }
    }, {
      key: "body_type",
      fn: function (data) {
        return [_c('span', {
          staticClass: "type-info d-flex float-left"
        }, [_vm._v(" " + _vm._s(_vm.type_map[data.value]) + " ")])];
      }
    }, {
      key: "body_status",
      fn: function (data) {
        var _vm$status_map$data$v, _vm$status_map$data$v2;

        return [_c('div', {}, [_c('table-status-text', {
          attrs: {
            "text": (_vm$status_map$data$v = _vm.status_map[data.value]) === null || _vm$status_map$data$v === void 0 ? void 0 : _vm$status_map$data$v.label,
            "colors": (_vm$status_map$data$v2 = _vm.status_map[data.value]) === null || _vm$status_map$data$v2 === void 0 ? void 0 : _vm$status_map$data$v2.colors
          }
        })], 1)];
      }
    }])
  }), _c('modal-print-price-tags', {
    attrs: {
      "products": _vm.printProducts
    }
  }), _c('table-navbar', {
    attrs: {
      "items": _vm.selected,
      "items_name": "promotion",
      "hide_print": true,
      "clear_selected": function () {
        return _this.selected = [];
      }
    },
    on: {
      "remove_items": _vm.remove_items,
      "print": _vm.printPriceTags,
      "print_price_tags": _vm.printPriceTags
    }
  }), _c('pagination', {
    attrs: {
      "skip": _vm.pagination.skip,
      "total": _vm.table.total,
      "table_name": "promo-doc"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }