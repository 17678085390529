<template>
  <div
    class="status"
    :style="{ background: colors.background, color: colors.text }"
  >
    {{ text }}
  </div>
</template>

<script>
  export default {
    name: 'TableStatusText',
    props: {
      text: String,
      colors: {
        background: String,
        text: String
      }
    }
  }
</script>

<style scoped>
  .status {
    display: inline;
    padding: 5px 12px;
    border-radius: 3px;
  }
</style>
