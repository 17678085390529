<template>
  <div class="document-filters">
    <select-picker
      v-model="status"
      :items="statuses"
      placeholder="Все статусы"
      class="select"
      @input="changeStatus"
      @change="changeStatus"
    />
    <div>
      <b-button
        variant="primary"
        @click="createHead('')"
      >
        <img
          src="/img/icons/btn-plus.svg"
          class="mr"
          alt=""
        />
        Создать акцию
      </b-button>
      <b-dropdown
        no-caret
        variant="none"
        class="ml-2"
      >
        <template #button-content>
          <div class="btn-more">
            <img
              src="/img/icons/settings.svg"
              alt=""
            />
          </div>
        </template>
        <b-dropdown-item @click="showSortModal"> Изменить столбцы </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
  import SelectPicker from '@/views/analytics/components/declaration/SelectPicker.vue'

  export default {
    name: 'DocumentsFilters',
    components: { SelectPicker },
    data() {
      return {
        status: null,
        statuses: [
          {
            key: 'all',
            label: 'Все статусы'
          },
          {
            key: 'ACTIVE',
            label: 'Активна'
          },
          {
            key: 'FINISHED',
            label: 'Завершена'
          },
          {
            key: 'AWAITING',
            label: 'Запланирована'
          },
          {
            key: 'DRAFT',
            label: 'Черновик'
          },
          {
            key: 'ERRORED',
            label: 'Ошибка'
          }
        ]
      }
    },
    methods: {
      changeStatus(val) {
        this.$emit('change_status', val)
      },
      createHead(type) {
        localStorage.setItem('newDocHeader', 'true')
        this.$emit('createHead', type)
      },
      showSortModal() {
        this.$bvModal.show('sort-table-modal')
      }
    }
    //TODO vuex
  }
</script>

<style scoped>
  .document-filters {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
  }
  .select {
    margin-right: 16px;
  }
  .picker {
    margin-left: auto;
  }
  .dropdown-icon {
    padding-right: 10px;
  }
  .dropdown-item-box {
    margin-right: 14px;
  }
</style>
