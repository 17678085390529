<template>
  <section id="promo__section">
    <documents-filters
      @createHead="createHead"
      @change_status="change_status"
      @change_type="change_type"
    />

    <resizable-table
      :busy="isBusy"
      table_name="promo_heads"
      :items="items"
      :default_fields="fields"
      empty_text="Ничего не найдено"
      @row-click="rowClick"
    >
      <template #body_id="data">
        <div
          class="b-statuses"
          :class="{
            success: data?.item.status === 'ACTIVE',
            error: data?.item.status === 'ERRORED',
            awaiting: data?.item.status === 'AWAITING',
            finished: data?.item.status === 'FINISHED'
          }"
        ></div>
        <div class="d-flex align-items-center">
          <div
            class="position-relative"
            style="width: 18px; height: 18px; margin-right: 10px"
            @click.stop=""
          >
            <e-checkbox
              :position="'center'"
              :checked="isSelectedItem(data.item.id)"
              @click="selectRow(data.item.id)"
            />
          </div>

          <div class="d-flex flex-column align-items-start">
            <div>{{ data.item.name || '-' }}</div>
            <span class="head-info">
              {{ data.item.number }}&nbsp; &nbsp;
              {{ formatDate(data.item.date, 'date') }}
            </span>
          </div>
        </div>
      </template>
      <template #body_start_date="data">
        <span class="d-flex float-left">
          {{ formatDate(data.item.start_date) }}
        </span>
      </template>
      <template #body_end_date="data">
        <span class="d-flex float-left">
          {{ formatDate(data.item.end_date) }}
        </span>
      </template>
      <template #body_type="data">
        <span class="type-info d-flex float-left">
          {{ type_map[data.value] }}
        </span>
      </template>
      <template #body_status="data">
        <div class="">
          <table-status-text
            :text="status_map[data.value]?.label"
            :colors="status_map[data.value]?.colors"
          />
        </div>
      </template>
    </resizable-table>
    <modal-print-price-tags :products="printProducts" />
    <table-navbar
      :items="selected"
      items_name="promotion"
      :hide_print="true"
      :clear_selected="() => (this.selected = [])"
      @remove_items="remove_items"
      @print="printPriceTags"
      @print_price_tags="printPriceTags"
    />
    <pagination
      :skip="pagination.skip"
      :total="table.total"
      table_name="promo-doc"
      @change_pagination="handlerPagination"
    />
  </section>
</template>

<script>
  import DocumentsFilters from '@/views/operational-processes/components/promo/DocumentsFilters.vue'
  import ResizableTable from '@/components/ResizableTable.vue'
  import { mapGetters } from 'vuex'
  import formatDate from '@/utils/formatDate'
  import TableStatusText from '@/views/operational-processes/components/promo/TableStatusText.vue'
  import TableNavbar from '../../../shared/components/table/TableNavbar.vue'
  import Pagination from '../../../shared/components/table/Pagination.vue'
  import ECheckbox from '@/components/ECheckbox'
  import { ProductModel } from '@/models/product.model'
  import ModalPrintPriceTags from '@/views/core/modal/ModalPrintPriceTags'

  export default {
    name: 'Documents',
    components: {
      ModalPrintPriceTags,
      ECheckbox,
      TableStatusText,
      ResizableTable,
      DocumentsFilters,
      TableNavbar,
      Pagination
    },
    apollo: {
      PromotionHeads: {
        query: require('../../gql/PromotionHeads.graphql'),
        variables() {
          return {
            input: {
              branch: this.currentBranch?.__typename === 'BranchClassifModel' ? null : this.currentBranch.id,
              status: this.filters.status,
              type: this.filters.type,
              pagination: {
                ...this.pagination
              }
            },
            group: this.currentBranch?.__typename === 'BranchClassifModel' ? this.currentBranch.id : null
          }
        },
        result({ data }) {
          this.selected = []
          this.table.total = data?.PromotionHeads.total
          this.items = data?.PromotionHeads.heads ?? []
          this.table.isBusy = false
        }
      }
    },
    data() {
      return {
        table: {
          isBusy: true,
          total: null
        },
        filters: {
          type: null,
          status: null
        },
        items: [],
        select_all: [],
        selected: [],
        pagination: {
          skip: 0,
          take: 20,
          search: ''
        },
        status_map: {
          DRAFT: {
            label: 'Черновик',
            colors: { background: '#EFEFEF', text: '#888888' }
          },
          AWAITING: {
            label: 'Запланирована',
            colors: { background: '#FFFAE7', text: '#D4A600' }
          },
          ACTIVE: {
            label: 'Активна',
            colors: { background: '#EBF9F5', text: '#00CB91' }
          },
          FINISHED: {
            label: 'Завершена',
            colors: { background: '#EFEFEF', text: '#888888' }
          },
          ERRORED: {
            label: 'Ошибка',
            colors: { background: '#EFEFEF', text: '#E53835' }
          }
        },
        fields: [
          { key: 'id', label: 'Акция', width: 300, checked: true },
          { key: 'start_date', label: 'Начало', width: 170, checked: true },
          { key: 'end_date', label: 'Конец', width: 170, checked: true },
          {
            key: 'status',
            label: 'Статус',
            width: 180,
            class: 'text-center',
            checked: true
          },
          {
            key: 'efficiency',
            label: 'Эффективность',
            width: 200,
            checked: true
          }
        ],
        title: this.$route.meta.title,
        printProducts: []
      }
    },
    mounted() {
      this.$apollo.queries.PromotionHeads.refetch()
    },
    methods: {
      async printPriceTags() {
        const { data } = await this.$apollo.query({
          query: require('../../gql/ReadPriceTagsFromDocument.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              document_head: this.selected[0],
              operation: 'promotion',
              branch: this.currentBranch.id,
              isPrintAll: true
            }
          }
        })
        this.printProducts = data?.ReadPriceTagsFromDocument?.map((el) => {
          return new ProductModel({ ...el?.Product, retailPrice: el?.value ?? el?.Product?.retailPrice })
        })
        if (this.printProducts?.length > 0) this.$bvModal.show('modal-print-tags')
      },
      selectRow(id) {
        if (this.selected.includes(id)) {
          this.selected = this.selected.filter((obj) => obj !== id)
        } else {
          this.selected.push(id)
        }
      },
      isSelectedItem(id) {
        return this.selected.includes(id)
      },
      change_status(val) {
        if (val !== 'all') {
          this.filters.status = val?.toUpperCase()
        } else {
          this.filters.status = null
          this.$apollo.queries.PromotionHeads.refetch()
        }
      },
      change_type(val) {
        if (val !== 'all') {
          this.filters.type = val
        } else {
          this.filters.type = null
          this.$apollo.queries.PromotionHeads.refetch()
        }
      },
      handlerPagination({ current_page = null, take = null }) {
        this.table.isBusy = true
        if (current_page) this.pagination.skip = Number((current_page - 1) * take)
        if (take) this.pagination.take = take
      },
      async remove_items(items) {
        this.selected = []
        await this.$apollo.mutate({
          mutation: require('./gql/RemovePromotionDocument.gql'),
          variables: {
            input: {
              id: items
            }
          }
        })
        this.$apollo.queries.PromotionHeads.refetch()
      },
      formatDate(value, type = 'datetime') {
        return value ? formatDate(new Date(value), type) : '-'
      },
      async createHead() {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/CreatePromotionHead.graphql'),
          variables: {
            input: {
              branch: this.currentBranch.id,
              operation: 'promotion'
            }
          }
        })
        this.$router.push({
          name: 'operation-process.overhead.promo.document.edit',
          params: { id: data.CreatePromotionHead.id }
        })
      },
      rowClick(el) {
        this.$router.push({
          name: 'operation-process.overhead.promo.document.edit',
          params: { id: el.id }
        })
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),
      isBusy() {
        return this.$apollo.queries.PromotionHeads.loading
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep th.cursor {
    padding: 16px !important;
  }
  ::v-deep th.cursor:not(:nth-child(5)) {
    div {
      justify-content: flex-start !important;
      display: flex;
      float: left;
    }
    .td-inner {
      width: 100%;
      padding-right: 30px;
      display: flex;
      justify-content: space-between !important;
    }
  }
  td {
    position: relative !important;
  }

  td.text-nowrap {
    position: relative !important;

    .b-statuses {
      width: 2px !important;
      height: 32px !important;
      position: absolute;
      left: 0px;
      top: 50%;
      background: #bcbcbc;
      border-radius: 0px 1px 1px 0px;
      transform: translate(0%, -50%);

      &.success {
        background: #00cb91;
      }

      &.awaiting {
        background: #e5b509;
      }

      &.error {
        background: #e53835;
      }
    }
  }

  .head-info {
    color: #888888;
    font-size: 12px;
  }

  .type-info {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  #promo__section {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
</style>
