<template>
  <div class="d-flex flex-column">
    <label v-if="label">{{ label }}</label>
    <i-select
      :disabled="disabled"
      class="select-picker"
      :placeholder="placeholder"
      :value="value"
      :style="{ width: width }"
      @on-change="change"
    >
      <i-option
        v-for="(item, index) in items"
        :key="index"
        :value="item.key"
        selected
      >
        {{ item.label }}
      </i-option>
    </i-select>
  </div>
</template>

<script>
  export default {
    name: 'SelectPicker',
    props: {
      label: String,
      disabled: Boolean,
      placeholder: String,
      width: String,
      value: String,
      items: { type: Array, default: () => [] }
    },
    methods: {
      change(val) {
        this.$emit('change', val)
      }
    }
  }
</script>

<style scoped></style>
