var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_vm.label ? _c('label', [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _c('i-select', {
    staticClass: "select-picker",
    style: {
      width: _vm.width
    },
    attrs: {
      "disabled": _vm.disabled,
      "placeholder": _vm.placeholder,
      "value": _vm.value
    },
    on: {
      "on-change": _vm.change
    }
  }, _vm._l(_vm.items, function (item, index) {
    return _c('i-option', {
      key: index,
      attrs: {
        "value": item.key,
        "selected": ""
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }